/// <reference types="vite-plugin-svgr/client" />
import { Button } from "@narmi/design_system";
import { OAuthProvider } from "./types";
import { providerToLogo } from "./utils";

function SSOButtonText({ provider, name }: { provider: string; name: string }) {
  const Logo = providerToLogo[provider];
  if (Logo) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo height="24px" />
        <span>Log in with {name}</span>
      </div>
    );
  } else {
    return `Log in with ${name}`;
  }
}

function OAuthProviders({
  providers,
  redirect,
  prompt,
}: {
  providers: OAuthProvider[];
  redirect: string;
  prompt: string | null;
}) {
  if (providers.length === 0) {
    return null;
  } else {
    return (
      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        {providers.map((provider: OAuthProvider, index: number) => {
          return (
            <Button
              key={provider.provider}
              style={{
                textDecoration: "unset",
                width: "100%",
                marginTop: index === 0 ? "0px" : "20px",
              }}
              href={`/oidc/${provider.provider}/redirect?next=${redirect}${prompt ? `&prompt=${prompt}` : ""}`}
              as="a"
              kind="secondary"
            >
              <SSOButtonText {...provider} />
            </Button>
          );
        })}
      </div>
    );
  }
}

export default OAuthProviders;
