import { useState } from "react";
import { Button, TextInput } from "@narmi/design_system";

const PasswordTextInput = ({
  label = "Password",
  labelShow = "Show",
  labelHide = "Hide",
  ...otherProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="password-input">
      <TextInput
        label={label}
        name="password"
        {...otherProps}
        type={showPassword ? "text" : "password"}
        error={otherProps.fieldError || otherProps.error}
      />
      <div className="show-hide-button">
        <Button
          type="button"
          kind="negative"
          size="xs"
          onClick={handleShowPassword}
          label={showPassword ? labelHide : labelShow}
        />
      </div>
    </div>
  );
};

export default PasswordTextInput;
